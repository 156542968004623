import React from 'react'

const SubscribeForm = () => (
  <form
    method="POST"
    action="https://stevencotterill.us18.list-manage.com/subscribe/post?u=89de3f7692b2e2300565c1665&id=08ec466885"
    className="w-full mx-auto mt-8"
    target="_blank"
  >
    <div className="text-white p-6 bg-primary rounded-3xl sm:py-16 sm:px-12 lg:p-20 lg:flex lg:items-center">
      <div className="lg:w-0 lg:flex-1 text-white">
        <h2 className="text-3xl tracking-tight">Sign up for my newsletter</h2>
        <p className="mt-4 max-w-3xl text-lg text-white">
          Get notified when I post a new article. Unsubscribe at any time, and I
          promise not to send any spam :)
        </p>
      </div>
      <div className="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 md:flex">
        <label htmlFor="email" className="sr-only">
          Email address
        </label>
        <input
          id="email"
          name="EMAIL"
          type="email"
          autoComplete="email"
          required
          className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none rounded-md"
          placeholder="Your email"
        />
        <button
          type="submit"
          className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
        >
          Sign up
        </button>
      </div>
    </div>
  </form>
)

export default SubscribeForm
