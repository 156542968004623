import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import SubscribeForm from "../components/SubscribeForm";

const About = () => (
  <Layout>
    <Seo
      title="About"
      description="I am a Web Developer based in Belfast. Find out more about me and what I do."
    />
    <div className="container mb-8 py-8">
      <div className="text-center mb-8 px-8">
        <h1 className="font-bold text-3xl">About</h1>
        <h2 className="pt-4 text-xl font-bold mb-5">
          A bit about me and what I do
        </h2>
      </div>

      <div className="prose prose-indigo lg:prose-xl max-w-none">
        <p>Hi! I am a Web Developer based in Belfast, Northern Ireland.</p>
        <p>
          The majority of my daily work focuses on back-end development using
          PHP. I have extensive experience with WordPress, Laravel, multiple
          front-end frameworks and APIs.
        </p>
        <p>
          I started this blog to document interesting issues and solutions to
          them, with the hope that it will also help others.
        </p>
        <h2>Website tech:</h2>
        <ul>
          <li>
            Built with <a href="https://reactjs.org">React</a> using{" "}
            <a href="https://www.gatsbyjs.org">Gatsby</a>.
          </li>
          <li>
            <a href="https://tailwindcss.com">Tailwind CSS</a>
          </li>
          <li>Netlify for hosting and form-handling</li>
          <li>Newsletter handled with Mailchimp</li>
        </ul>
        <hr className="my-10" />
      </div>
      <SubscribeForm />
    </div>
  </Layout>
);

export default About;
